<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img
            id="LogoImageId_LoginPage"
            src="@/assets/images/logo/logo.png"
            alt="Logo"
            height="75"
          >

          <h2 class="brand-text text-primary ml-1">
            Gezira
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Welcome to Gezira Dental Clinic! 👋
        </b-card-title>
        <!-- <b-card-text class="mb-2">
          Please sign-in to your account and start the adventure
        </b-card-text> -->

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- username -->
            <b-form-group
              label-for="username"
              label="UserName"
            >
              <validation-provider
                #default="{ errors }"
                name="UserName"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="LoginModel.UserName"
                  name="login-username"
                  :state="errors.length > 0 ? false:null"
                  placeholder="UserName"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="LoginModel.Password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="LoginModel.checkbox_remember_me"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>

        <!-- <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{name:'auth-register-v1'}">
            <span>Create an account</span>
          </b-link>
        </b-card-text> -->

        <div class="divider my-2">
          <div class="divider-text">
            Medical System
          </div>
        </div>

      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BInputGroup, BInputGroupAppend, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    // BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      LoginModel: {
        UserName: '',
        Password: '',
        // UserName: 'SuperAdmin@SuperAdmin.com',
        // Password: '24384804',
        // FirebaseToken: 'Empty',
        // checkbox_remember_me: false,
      },
      status: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login(this.LoginModel)
            .then(response => {
              // console.log(response.data.Data.User.Roles.length)
              // const userData = this.user
              const userData = response.data.Data.User
              useJwt.setToken(response.data.Data.AccessToken)
              useJwt.setRefreshToken(response.data.Data.RefreshToken)
              userData.Ability = [
                {
                  action: 'manage',
                  subject: 'all',
                },
              ]
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.Ability)
              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.user.extras.eCommerceCartItemsCount)
              if (userData.Roles.length > 0) {
                // this.$acl.change(userData.Roles[0])
                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                this.$router.replace(getHomeRouteForLoggedInUser(userData.Roles[0]))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Welcome ${userData.FirstName} ${userData.LastName}`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: `You have successfully logged in as ${userData.Roles[0]}. Now you can start to explore!`,
                      },
                    })
                  })
              }
              // this.$router.push(this.$router.currentRoute.query.to || '/')
            })
            .catch(error => {
              // this.$refs.loginForm.setErrors(error.response.data.error)
              this.$refs.loginForm.setErrors(error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-wrapper .brand-logo .brand-text {
    font-weight: 600;
    vertical-align: middle;
    margin-top: 7px;
}
#LogoImageId_LoginPage {
    border-style: none;
    height: 40px;
}
</style>
